import React, { Component } from "react";

class Footer extends Component{
    render(){
        return(
            <div>
                <section className="footer-sec">
                <div className="container footer">
                    <p>Copyright © 2024 Amros | All Rights Reserved</p>
                </div>
                </section>
            </div>
        )
    }
}

export default Footer